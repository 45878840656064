<template>
  <div class="result-data-entry">
    <div>
    <a-modal
      v-model="visible"
      width="726px"
      footer=""
      :mask-closable="false"
      :closable="stage !== UploadStage.SENDING"
      @cancel="closeModalWithConfirm">
      <h1>{{ $t('components.titles.uploadData') }}</h1>
      <a-row class="Tab" type="flex" justify="start">
        <a-col :class="['txt-18', (stage === UploadStage.CHOOSE || stage === UploadStage.UPLOAD) ? null : 'disabled-color']" :span="6">{{ $t('components.labels.uploadFile', { number: 1}) }}</a-col>
        <a-col :class="['txt-18', (stage === UploadStage.PROCESS) ? null : 'disabled-color']" :span="6">{{ $t('components.labels.processing', { number: 2 }) }}</a-col>
        <a-col :class="['txt-18', (stage === UploadStage.CONFIRM) ? null : 'disabled-color']" :span="8">{{ $t('components.labels.mappingCauseOfDeath', { number: 3 }) }}</a-col>
      </a-row>
      <hr>
      <div class="Container">
        <div v-if="stage === UploadStage.CHOOSE">
          <p class="txt-14">{{ $t('components.description.pleaseSelectAFileYouWantToUpload') }}</p>
          <div class="upload">
            <a-upload
              :file-list="fileList"
              :custom-request="() => {}"
              accept=".csv, .xlsx, text/csv, application/csv,
                text/comma-separated-values, application/csv, application/excel"
              :before-upload="selectUploadfile">
              <img src="/upload-data.svg" width="80px">
              <p class="UPLOAD-ENUMERATORS-F">
                {{ $t('components.description.uploadFileXls') }}
              </p>
            </a-upload>
          </div>
        </div>
        <div v-if="stage === UploadStage.UPLOAD">
          <p class="txt-14">{{ $t('components.description.pleaseSelectAFileYouWantToUpload') }}</p>
          <div class="Rectangle">
            <p style="margin-right: auto;">{{ uploadFile.name }}</p>
            <p style="margin-right: 50px">{{ uploadFile.size }}</p>
            <a @click="resetStages"><img src="/trash-bin.svg"></a>
          </div>
        </div>
        <div v-if="stage === UploadStage.PROCESS || stage === UploadStage.ERROR">
          <p class="txt-14">{{ $t('components.description.fileSelectedInProcess') }}</p>
          <div class="Rectangle">
            <p style="margin-right: auto;">{{ uploadFile.name }}</p>
            <p style="margin-right: 50px">{{ uploadFile.size }}</p>
            <a-spin v-if="stage === UploadStage.PROCESS" size="large"/>
          </div>
          <a-alert v-if="stage === UploadStage.ERROR" :message="$t('components.notifications.errorProcessingFile')" type="error" show-icon/>
        </div>
        <div v-if="stage === UploadStage.SENDING">
          <p class="txt-14">Sending data to the server. This process may take a while...</p>
          <div class="loading-container">
            <a-spin size="large"/>
          </div>
        </div>
        <div v-if="stage === UploadStage.CONFIRM" style="height: 100%;">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <div v-if="matchCauseOfDeath.keys.length === 0" v-html="$t('components.description.noMappingIsRequired')">
          </div>
          <div v-else style="height: 100%;">
            <!-- eslint-disable-next-line vue/no-v-html -->
            <p class="txt-14" v-html="$t('components.description.mapFollowingCauseOfDeath', { causeOfDeath: matchCauseOfDeath.keys.length, entry: $tc('values.entry', matchCauseOfDeath.keys.length) })">
            </p><div class="mt-24 code-map-container">
              <a-row class="mb-10">
                <a-col class="txt-12 txt-bold" :span="12">{{ $t('components.labels.codeInFile') }}</a-col>
                <a-col class="txt-12 txt-bold" :span="12">{{ $t('components.labels.smartplusCode') }}</a-col>
              </a-row>
              <a-row v-for="code in matchCauseOfDeath.keys" :key="code" class="mb-10">
                <a-col :span="12">
                  <div class="mr-24">
                    <a-input
                      class="w300"
                      :value="code"
                      size="large"
                      :disabled="true"
                      :editable="false"/>
                  </div>
                </a-col>
                <a-col :span="12">
                  <div class="mr-24 w300">
                  <a-select class="code-picker" :placeholder="$t('components.description.causeOfDeath')" :get-popup-container="(triggerNode) => triggerNode.parentNode"
                  @change="onChange">
                      <a-icon slot="suffixIcon" type="caret-down"/>
                      <a-select-option v-for="smartCode in causeOfDeath" :key="smartCode.code" :filekey="code">
                        {{ smartCode.code }} - {{ translateCauseOfDeath(smartCode.label) }}
                      </a-select-option>
                    </a-select>
                  </div>
                </a-col>
              </a-row>
            </div>
          </div>
        </div>
      </div>
      <div v-if="stage != UploadStage.PROCESS" style="display: flex;">
        <a-button v-if="stage === UploadStage.ERROR" key="submit-close" class="TabButton" type="primary" @click="closeModal">
          {{ $t('values.close') }}
        </a-button>
        <a-button v-else-if="stage === UploadStage.CHOOSE" key="submit-upload" class="TabButton-disabled" type="primary">
          {{ $t('values.continue') }}
        </a-button>
        <a-button v-else-if="stage === UploadStage.UPLOAD" key="submit-process" class="TabButton" type="primary" @click="matchCauseOfDeathValue">
          {{ $t('values.continue') }}
        </a-button>
        <a-button v-else-if="stage === UploadStage.CONFIRM" key="submit-confirm" class="TabButton" type="primary"
          :disabled="hasNotMatchedCauseOfDeath"
          @click="handleConfirm">
          {{ $t('values.confirm') }}
        </a-button>
      </div>
    </a-modal>
  </div>
    <div class="flex align-center mt-10 mb-20">
      <a-button
        type="link"
        class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center"
        :disabled="isSupervisor"
        @click="showModal">
        <embed width="30px" :disabled="isSupervisor" src="/Upload-orange.svg" class="mr-10">
        {{ $t('components.description.uploadData') }}
      </a-button>
      <a-dropdown>
        <template #overlay>
          <a-menu>
            <a-menu-item @click="download">
              {{ $t('components.dropdown.downloadTableData') }}
            </a-menu-item>
            <a-menu-item @click="openOriginalSubmissionsDownloadModal">
              {{ $t('components.dropdown.downloadOriginalData') }}
            </a-menu-item>
            <a-menu-item @click="downloadAdditionalIndicators">
              {{ $t('components.dropdown.downloadAdditionalIndicator') }}
            </a-menu-item>
          </a-menu>
        </template>
        <a-button
          type="link"
          class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center">
          <embed width="30px" src="/icon-cloud-download.svg" class="mr-10">
          {{ $t('components.description.downloadData') }}
        </a-button>
      </a-dropdown>
      <a-button
        type="link"
        class="txt-18 txt-black txt-font-din-medium mr-10 flex align-center"
        @click="onPlausibilityRequest">
        <embed width="30px" src="/download-upload-icon.svg" class="mr-10">
        {{ $t('components.description.requestPlausibilityReport') }}
      </a-button>
    </div>
    <a-card :bordered="true" class="ant-card-no-padding mb-20">
      <div class="mb-10 pl-20 pr-20 pt-20">
        <div class="txt-20 txt-black txt-bold txt-font-din-medium mb-10 flex align-center">
          {{ $t('components.titles.dataEntry') }}
          <!--<a-tooltip
            title=" "
            class="ml-8">
            <a-icon type="info-circle" class="pt-0" style="color: #c6c7c6; font-size: 20px"/>
          </a-tooltip>-->
        </div>
        <DeleteDataSetModal
          :data-set="dataSetToDelete"
          :visible="deleteDataSetModalVisible"
          :source-column="headerKeys.indexOf('source')"
          :hot="$refs.hot && $refs.hot.hotInstance"
          :header-configs="headerConfigs"
          @close-modal="closeDeleteDataSetModal"
          @set-data-changes="setDataFromDeleteDatasetModal"/>
        <DatasetDisplay
          :source-column="headerKeys.indexOf('source')"
          :hot="$refs.hot && $refs.hot.hotInstance"
          @delete-data-set="deleteDataSet"/>
        <div class="flex justify-space-between mb-20">
          <div class="flex align-center">
            <DuplicationCheck
              :key="headerKeys.length"
              :clear-filter="clearFilter"
              :filtered-count="filters.length"
              :hot="$refs.hot && $refs.hot.hotInstance"
              :columns="duplicateHeaderkeys"
              :column-names="duplicateHeaderNames"
              :count="householdsCount"
              @selectedColumns="selectedColumnsEvent"/>
            <TableRowColumnControls
              :hot="$refs.hot && $refs.hot.hotInstance"
              :filters="filters"/>
          </div>
        </div>
      </div>
      <DuplicationCount
        :selected-columns="selectedColumns"
        :hot="$refs.hot && $refs.hot.hotInstance"
        :hidden="hidden"
        :filtered="filters.length !== 0"
        :filtered-hidden-rows="filteredHiddenRows"
        :count="householdsCount"
        @clearDuplicateFilter="clearDuplicateFilter"/>
      <div class="mb-10 pl-20 pr-20 pt-20">
        <div v-if="cellErrorCount !== 0" class="expand-error">
          <div class="pl-10 flex">
            <embed width="20px" height="20px" src="/alert.svg" class="mr-10 mt-20">
            <div>
              <div class="pt-20 flex">
                <p class="mr-20">{{ $tc('components.notifications.cellsFoundWithError', cellErrorCount, { errors: cellErrorCount }) }}</p>
                <a class="mr-10 red-color txt-bold" @click="showErrorDetail=!showErrorDetail;">{{ !showErrorDetail ? $t('components.description.showMore') : $t('components.description.showLess') }}<embed src="/show-more-red.svg" :class="['ml-4', showErrorDetail ? '': 'rotate180']"></a>
              </div>
              <div v-show="showErrorDetail" class="pb-20">
                {{ $t('components.labels.errorsFoundInTheFollowingCols') }} <b class="txt-bold">{{ cellErrorColumns }}</b>
                <br>
                {{ $t('components.labels.errorsFoundInTheFollowingRows') }} <b class="txt-bold">{{ cellErrorRows }}</b>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div :style="{'max-width': dataEntryWidth}">
        <hot-table
          v-if="normalizedMortalityData"
          ref="hot"
          class="table-body"
          stretch-h="none"
          license-key="non-commercial-and-evaluation"
          :settings="hotSettings"/>
      </div>
      <div class="table-footer">
        <div class="txt-18 txt-bold txt-font-din-medium txt-black">
          {{ $t('components.labels.totalHouseholds', { count: householdsCount }) }}
        </div>
        <div class="txt-italic txt-grey mr-20">
          {{ $t('components.description.recallDaysFromPlanningSheet', { days: mortalityRecall }) }}
        </div>
      </div>
    </a-card>
    <PlausibilityReportLoadingModal
      :show="showPlausibilityModal"
      :is-fetching="mortalityPlausibilityIsFetching"
      :on-exit="closePlausibilityModal"
      :file-blob="mortalityPlausibilityBlob"
      file-name="mortality_plausibility.docx"
      :request-time="plausibilityRequestTime"/>
    <OriginalSubmissionsDownloadModal
      :show="showOriginalSubmissionsDownloadModal"
      :on-exit="closeOriginalSubmissionsDownloadModal"/>

    <HouseholdSummaryTable v-if="rowSummaryObject" :row-summary-object="rowSummaryObject"/>
    <div :class="['htCommentsContainer', hiddenComment]">
      <div :style="{display: 'block', position: 'fixed', left: leftComment + 'px', top: topComment + 'px'}" class="htComments">
        <div :class="['htCommentTextArea', colorComment]">
          <a :class="[colorComment + '-color', 'txt-bold']">{{ titleComment }}</a>
          <p>{{ contentComment }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import { mapActions, mapGetters, mapState } from 'vuex';
import { HotTable } from '@handsontable/vue';
import Papa from 'papaparse';
import XLSX from 'xlsx';
import cloneDeep from 'lodash/cloneDeep';
import flatten from 'lodash/flatten';
import omit from 'lodash/omit';
import uniq from 'lodash/uniq';
import moment from 'moment';

import { handsontableFilterDropdown, handsontableRowContextMenu } from '../../shared';
import { formatSmartDates, validDateFormat, dateFormats } from '../../../../util/date-time';
import { downloadBase64FromBlob } from '../../../../util/standardization';

import {
  chunkDataUploadArray,
  shapeUploadRequestData,
  shapeEnaFileUpload,
  filterEmptyChanges,
  shapeMortalityUpdateRequestData,
  moveToNextRowAndOffset,
  moveToNextCol,
  applyAutoPopulationToMortalityCell,
  filterNonChanges
} from '../../../../util/handsontable';
import { configForPossibleBackendRequest } from '../../../../util/request';
import { convertStringToNumeric } from '../../../../util/util';
import { getMortalitySettingsSheet } from '../../../../util/data-entry';

const uploadMessageKey = 'uploadMessageKey';
const UploadStage = {
  CHOOSE: 'choose',
  UPLOAD: 'upload',
  PROCESS: 'process',
  CONFIRM: 'confirm',
  ERROR: 'error',
  SENDING: 'sending'
};

export default {
  name: 'MoralityDataEntry',
  components: {
    HotTable,
    HouseholdSummaryTable: () => import('../DataEntry/household-summary-table.vue'),
    TableRowColumnControls: () => import('../../TableRowColumnControls/index'),
    DatasetDisplay: () => import('../../DatasetDisplay/index'),
    DeleteDataSetModal: () => import('../../DeleteDataSetModal/index'),
    DuplicationCheck: () => import('../../DuplicationCheck/index'),
    DuplicationCount: () => import('../../DuplicationCount/index'),
    PlausibilityReportLoadingModal: () => import('../../PlausibilityModal/plausibility-report-loading-modal.vue'),
    OriginalSubmissionsDownloadModal: () => import('../../Modals/original-submissions-download-modal.vue')
  },
  props: {
    disableClusters: {
      type: Boolean,
      required: false,
      default: undefined
    },
    onEntryChange: {
      type: Function,
      required: true
    }
  },
  data() {
    const translateDropdownMenu = handsontableFilterDropdown(this);
    const translateRowContext = handsontableRowContextMenu(this);
    return {
      deleteDataSetModalVisible: false,
      dataSetToDelete: '',
      waitingForDataSetDeleteRequest: false,
      visible: false,
      filteredHiddenRows: [],
      UploadStage,
      selectedColumns: false,
      clearFilter: false,
      backspaceDown: false,
      stage: 'choose',
      uploadFile: {
        name: '',
        size: '',
        file: undefined,
        parseAsEna: false,
        shapedEnaData: undefined,
        arrayData: undefined
      },
      hiddenComment: 'hidden',
      colorComment: '',
      titleComment: '',
      contentComment: '',
      leftComment: 0,
      topComment: 0,
      matchCauseOfDeath: {},
      showPlausibilityModal: false,
      plausibilityRequestTime: 0,
      plausibilityRequestTimeIntervalId: null,
      showOriginalSubmissionsDownloadModal: false,
      householdsCount: 0,
      rowSummaryObject: {},
      mounted: false,
      isUploading: false,
      filters: [],
      fileList: [],
      fileName: undefined,
      hidden: [],
      data: [],
      headerNames: [],
      duplicateHeaderNames: [],
      duplicateHeaderkeys: [],
      headerKeys: [],
      headerConfigs: [],
      columns: [],
      commentCells: {},
      showErrorDetail: false,
      cellErrorCount: 0,
      cellErrorColumns: '',
      cellErrorRows: '',
      filterApplied: false,
      // setting a fixed width for the data entry table, because setting
      // 100% width puts the scrollbar at the edge of the screen instead
      // of next to the table
      dataEntryWidth: '1165px',
      hotSettings: {
        height: 550,
        width: '100%',
        columnSorting: true,
        colWidths: 100,
        sortIndicator: false,
        rowHeaders: true,
        rowHeights: 24,
        fillHandle: false,
        autoRowSize: false,
        autoColumnSize: false,
        outsideClickDeselects: false,
        filters: true,
        hiddenRows: true,
        dropdownMenu: translateDropdownMenu,
        contextMenu: translateRowContext,
        minSpareRows: 10,
        minRows: 20,
        wordWrap: false,
        beforeRemoveRow: (index, amount, physicalRows) => {
          this.$message.loading({ content: this.$t('components.notifications.deletingData'), key: uploadMessageKey });
        },
        afterRemoveRow: (index, amount, physicalRows) => {
          for (const physicalRow of physicalRows) {
            delete this.commentCells[physicalRow];
          }
          this.updateCellErrorBarData();
        },
        afterColumnSort: (currentSortConfig, destinationSortConfigs) => {
          this.reRenderingCells(true);
          this.updateCellErrorBarData();
        },
        afterOnCellMouseOver: (event, coords, TD) => {
          if (TD.classList.contains('htCommentCell')) {
            const physicalRow = this.$refs.hot.hotInstance.toPhysicalRow(coords.row);
            this.colorComment = this.commentCells[physicalRow][coords.col].color;
            this.titleComment = this.commentCells[physicalRow][coords.col].errorTitle;
            this.contentComment = this.commentCells[physicalRow][coords.col].errorMessage;
            this.hiddenComment = '';
            const rect = TD.getBoundingClientRect();
            this.leftComment = rect.x + rect.width;
            this.topComment = rect.y;
          }
        },
        afterOnCellMouseOut: (event, coords, TD) => {
          this.hiddenComment = 'hidden';
        },
        enterMoves: () => {
          const instance = this.$refs.hot.hotInstance;
          const selectedRow = instance.getSelected();
          const colCount = instance.countCols();
          const currentRow = selectedRow[0][0];
          const currentCol = selectedRow[0][1];

          const nextCellMeta = instance.getCellMeta(currentRow, currentCol + 1);
          if (nextCellMeta.readOnly) {
            let offsetValue = 1;
            let i = currentCol + 1;
            while (i < colCount) {
              const cellMeta = instance.getCellMeta(currentRow, i);
              if (cellMeta.readOnly) {
                offsetValue += 1;
              } else {
                break;
              }
              i += 1;
            }
            // Check for last several rows being read only
            if (i === colCount) {
              const lastPopulatedKey = applyAutoPopulationToMortalityCell(instance, this.mortalityAutoPopulation, this.headerKeys, currentRow);
              let offset = 3;
              if (lastPopulatedKey) {
                offset = Math.min(this.headerKeys.indexOf(lastPopulatedKey) + 1, colCount - 1);
              }
              return moveToNextRowAndOffset(instance, currentRow, offset); // account for id, hh_id
            }

            return {
              row: 0,
              col: offsetValue
            };
          }

          // "Enter" hit on last column
          if (currentCol === instance.countCols() - 1) {
            const lastPopulatedKey = applyAutoPopulationToMortalityCell(instance, this.mortalityAutoPopulation, this.headerKeys, currentRow);
            let offset = 3;
            if (lastPopulatedKey) {
              offset = Math.min(this.headerKeys.indexOf(lastPopulatedKey) + 1, colCount - 1);
            }
            return moveToNextRowAndOffset(instance, currentRow, offset); // account for id, hh_id
          }

          return moveToNextCol();
        },
        afterHideRows: (currentHideConfig, destinationHideConfig) => {
          if (this.filters.length === 0) {
            this.hidden = [...destinationHideConfig.filter(item => item !== null)];
          } else {
            this.filteredHiddenRows = [...destinationHideConfig.filter(item => item !== null)];
          }
        },
        beforePaste: (data, coords) => {
          data.forEach((rowData, rowIndex) => {
            rowData.forEach((columnData, columnIndex) => {
              // eslint-disable-next-line no-param-reassign
              data[rowIndex][columnIndex] = columnData.replace(/\r?\n|\r/g, '');
            });
          });
        },
        afterPaste: () => {
          this.$refs.hot.hotInstance.deselectCell();
        },
        afterFilter: (conditions) => {
          this.filters = [...conditions];
          this.countRows();
          this.reRenderingCells(false);
          this.filterApplied = conditions.length !== 0;
          this.updateCellErrorBarData();
        },
        afterChange: (changes, source) => {
          const customSources = [
            'clearing_cluster_column',
            'dateValidator',
            'update_id',
            'reversing_change',
            'file_upload'
          ];
          if (customSources.includes(source)) {
            return;
          }
          if (changes && filterNonChanges(changes).length === 0) {
            return;
          }

          // Save Row edits or new row additions
          if (changes && !this.isUploading) this.saveRow(changes.map((item) => [...item, source]));
          this.countRows();
        },
        afterRender: () => {
          // This logic is used to improve the perceived
          // performance allowing the tab to load before fetching data
          if (!this.mounted) {
            setTimeout(() => this.setDataFromStore(this.normalizedMortalityData), 1000);
          }
          this.mounted = true;
        },
        beforeKeyDown: (event) => {
          const key = event.keyCode || event.charCode;
          // 8 is backspace, 46 is delete
          if (key === 8 || key === 46) {
            this.backspaceDown = true;
          }
        },
        // eslint-disable-next-line consistent-return
        beforeChange: (changes, source) => {
          if (source === 'UndoRedo.undo' || source === 'UndoRedo.redo') {
            if (changes.every((item) => {
              const [_, col, oldVal, newVal] = item;
              return col === 0 && oldVal != null && newVal != null;
            })) {
              this.$refs.hot.hotInstance.undoRedo.undo();
              return false;
            }
          }
          if (source === 'CopyPaste.paste' || source === 'edit') {
            for (let index = 0; index < changes.length; index += 1) {
              const change = changes[index];
              if (change[3] != null) {
                switch (change[1]) {
                  case this.headerKeys.indexOf('sex'):
                    if (change[3] === 'M' || change[3] === 'F') {
                      change[3] = change[3].toLowerCase();
                    }
                    break;
                  case this.headerKeys.indexOf('survdate'):
                    change[3] = formatSmartDates(change[3]);
                    break;
                  case this.headerKeys.indexOf('born'):
                  case this.headerKeys.indexOf('joined'):
                  case this.headerKeys.indexOf('left'):
                  case this.headerKeys.indexOf('died'):
                    if (parseInt(change[3], 10) === 0 || change[3]?.toLowerCase?.()?.trim?.() === 'n') {
                      change[3] = null;
                    } else if (parseInt(change[3], 10) === 1 || change[3]?.trim?.() === 'Y') {
                      change[3] = 'y';
                    }
                    break;
                  default:
                }
              }
            }
          }
        },
        afterUndo: (action) => {
          if (action && action.actionType === 'remove_row') {
            // if undoing a remove_row, afterChanges is not called so we need to save the changes here
            const changes = action.data.flatMap((row, rowIndexOffset) => row.map((item, index) => [action.index + rowIndexOffset, index, null, item, action.actionType]));
            this.saveRow(changes);
          }
        },
        beforeUndo: (action) => {
          if (action && action.data && action.actionType === 'remove_row') {
            const pkHeaderIndex = this.headerConfigs.findIndex((config) => config.key === 'id');
            if (action.data.some((row) => row[pkHeaderIndex] === null)) {
              return true;
            }
            const removedIndexes = action.data.map((_, index) => action.index + index);
            if (this.$refs.hot.hotInstance?.undoRedo?.doneActions && this.$refs.hot.hotInstance.undoRedo.doneActions.length > 0) {
              const beforeRemoveAction = this.$refs.hot.hotInstance.undoRedo.doneActions[this.$refs.hot.hotInstance.undoRedo.doneActions.length - 1];
              const changedRowsUniqIndexes = beforeRemoveAction?.changes ? uniq(beforeRemoveAction.changes.map((change) => change[0])) : [];
              if (beforeRemoveAction && (beforeRemoveAction.actionType === 'remove_row' || (beforeRemoveAction.actionType === 'change' && removedIndexes.length > 0 && removedIndexes.every((index) => changedRowsUniqIndexes.includes(index))))) {
                this.$refs.hot.hotInstance.alter('insert_row', action.index, action.data.length, 'undo_row_remove');
                return false;
              }
            }
          }
          return true;
        },
        beforeUndoStackChange: (_, source) => {
          if (source === 'undo_row_remove') {
            this.$refs.hot.hotInstance.undoRedo.undo();
          }
          if (source === 'undo_row_remove' || source === 'update_id') {
            return false;
          }
          return true;
        }
      }
    };
  },
  computed: {
    ...mapGetters(['normalizedMortalityData', 'mortalityAutoPopulation', 'mortalityPlausibilityBlob', 'mortalityPlausibilityIsFetching', 'showLocationOfDeath', 'showCauseOfDeath', 'causeOfDeath']),
    ...mapState({
      mortalityRecall: state => parseInt(state.survey?.metadata?.mortalityRecall || 0, 10),
      surveyId: state => state.survey.surveyId,
      surveyName: state => state.survey.name,
      projectId: state => state.survey.project.id,
      plausibilityError: state => state.plausibility.error,
      mortalitySettings: (state) => state.results.mortalitySettings,
      isSupervisor: state => state.survey.currentUserRoleSystem === 'srvy-sup'
    }),
    hasNotMatchedCauseOfDeath() {
      return (Object.keys(this.matchCauseOfDeath).filter(item => this.matchCauseOfDeath[item] === undefined).length !== 0);
    }
  },
  watch: {
    normalizedMortalityData() {
      this.setDataFromStore(this.normalizedMortalityData);
    },
    '$i18n.locale': function() {
      const translatedHeaders = this.translateHeaders();
      const headerNames = this.headerKeys.map((key) => (translatedHeaders.find((k) => (key === k.key)).title));
      const translateDropdownMenu = handsontableFilterDropdown(this);
      const translateRowContext = handsontableRowContextMenu(this);
      this.$refs.hot.hotInstance.updateSettings({
        colHeaders: headerNames,
        dropdownMenu: translateDropdownMenu,
        contextMenu: translateRowContext
      });
      this.duplicateHeaderNames = this.duplicateHeaderkeys.map((key) => translatedHeaders.find((x) => x.key === key).title);
    },
    showLocationOfDeath() {
      const plugin = this.$refs.hot.hotInstance.getPlugin('hiddenColumns');
      const locationIndex = this.headerKeys.indexOf('location');
      if (this.showLocationOfDeath) {
        plugin.showColumn(locationIndex);
      } else {
        plugin.hideColumn(locationIndex);
      }
    },
    showCauseOfDeath() {
      const plugin = this.$refs.hot.hotInstance.getPlugin('hiddenColumns');
      const causeIndex = this.headerKeys.indexOf('cause');
      if (this.showCauseOfDeath) {
        plugin.showColumn(causeIndex);
      } else {
        plugin.hideColumn(causeIndex);
      }
      this.$refs.hot.hotInstance.render();
    }
  },
  mounted() {
    this.$message.loading({ content: this.$t('components.notifications.loadingData'), duration: 1 });
    if (this.isSupervisor && this.$refs?.hot?.hotInstance) {
      this.$refs.hot.hotInstance.updateSettings({
        readOnly: true,
        contextMenu: false,
        manualColumnResize: false,
        manualRowResize: false,
        comments: false
      });
    }
  },
  methods: {
    selectedColumnsEvent($event) {
      this.selectedColumns = $event;
    },
    clearDuplicateFilter() {
      this.clearFilter = !this.clearFilter;
    },
    onChange(item, value) {
      this.matchCauseOfDeath[value.data.attrs.filekey] = item;
    },
    showModal() {
      this.visible = true;
    },
    closeModalWithConfirm() {
      if (this.stage !== UploadStage.SENDING) {
        this.closeModal();
      }
    },
    closeModal() {
      this.visible = false;
      this.stage = UploadStage.CHOOSE;
    },
    handleConfirm(e) {
      if (this.hasNotMatchedCauseOfDeath) {
        this.$alert().danger(this.$t('pleaseEnsureAllFieldsAreMatched'));
        return;
      }
      let arrayData = [];
      if (this.uploadFile.parseAsEna) {
        const { shapedEnaData } = this.uploadFile;
        arrayData.push(Object.keys(shapedEnaData[0]));
        shapedEnaData.forEach(item => {
          if ((item.cause || item.died === 'y') && this.matchCauseOfDeath.keys.includes(String(item.cause))) {
            /* eslint-disable no-param-reassign */
            item.cause = this.matchCauseOfDeath[item.cause];
          }
          arrayData.push(Object.keys(item).map(key => item[key]));
        });
        this.uploadFile.parseAsEna = false;
      } else {
        arrayData = [...this.uploadFile.arrayData];
        const causeIndex = arrayData[0].map(item => item.toLowerCase()).indexOf('cause');
        const diedIndex = arrayData[0].map(item => item.toLowerCase()).indexOf('died');
        arrayData.forEach(item => {
          if ((item[causeIndex] || item[diedIndex] === 'y') && this.matchCauseOfDeath.keys.includes(String(item[causeIndex]))) {
            /* eslint-disable no-param-reassign */
            item[causeIndex] = this.matchCauseOfDeath[item[causeIndex]];
          }
        });
      }
      this.$message.loading({ content: this.$t('components.notifications.uploadingData'), key: uploadMessageKey });
      this.uploadDataFromFile(arrayData, this.uploadFile.name, this.uploadFile.parseAsEna);
      this.stage = UploadStage.SENDING;
    },
    matchCauseOfDeathValue(r) {
      this.stage = UploadStage.PROCESS;
      const fileNameLowercased = this.uploadFile.name.toLowerCase();
      if (fileNameLowercased.endsWith('.xlsx') || fileNameLowercased.endsWith('.xls')) {
        this.parseDataFromXlsxFile(this.uploadFile.file).then(() => {
          this.stage = UploadStage.CONFIRM;
        }).catch(() => {
          this.stage = UploadStage.ERROR;
        });
      } else {
        Papa.parse(this.uploadFile.file, {
          skipEmptyLines: 'greedy',
          worker: true,
          complete: async (result) => {
            const headerKeys = result.data[0].map(item => item.toLowerCase());
            const causeIndex = headerKeys.indexOf('cause');
            const diedIndex = headerKeys.indexOf('died');
            const matchCauseOfDeath = {};
            result.data.forEach((item, index) => {
              if (index !== 0 && !matchCauseOfDeath[item[causeIndex]] && item[causeIndex]) {
                matchCauseOfDeath[item[causeIndex]] = undefined;
              }
            });
            matchCauseOfDeath.keys = Object.keys(matchCauseOfDeath);
            this.matchCauseOfDeath = matchCauseOfDeath;
            this.uploadFile.arrayData = result.data;
            this.stage = UploadStage.CONFIRM;
          },
          error: (err => {
            this.stage = UploadStage.ERROR;
            this.$message.danger({ content: this.$t('components.notifications.errorImportingCSV') });
          })
        });
      }
    },
    async parseDataFromXlsxFile(file) {
      const buffer = await file.arrayBuffer();
      /* Parse data */
      const wb = XLSX.read(new Uint8Array(buffer), { type: 'array' });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];

      // lowercase headers
      const range = XLSX.utils.decode_range(ws['!ref']);

      // Check if whether to parse by ENA
      let parseAsEna = false;
      for (let C = range.s.c; C <= range.e.c; C += 1) {
        const address = `${XLSX.utils.encode_col(C)}1`; // <-- first row, column number C
        if (ws[address]) {
          ws[address].v = ws[address].v.toLowerCase();
          if (ws[address].v === 'p1_sex' || ws[address].v === 'p1_age') {
            parseAsEna = true;
          }
        }
      }
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1, blankrows: false });
      const matchCauseOfDeath = {};
      if (parseAsEna) {
        const shapedEnaData = shapeEnaFileUpload(data[0], data, file.name).filter((item) => !/p\d+_sex/.test(item.sex));
        shapedEnaData.forEach(item => {
          if (!matchCauseOfDeath[item.cause] && item.cause) {
            matchCauseOfDeath[item.cause] = undefined;
          }
        });
        this.uploadFile.shapedEnaData = shapedEnaData;
      } else {
        const causeIndex = data[0].indexOf('cause');
        const diedIndex = data[0].indexOf('died');
        data.forEach((item, index) => {
          if (index !== 0 && !matchCauseOfDeath[item[causeIndex]] && item[causeIndex]) {
            matchCauseOfDeath[item[causeIndex]] = undefined;
          }
        });
      }
      matchCauseOfDeath.keys = Object.keys(matchCauseOfDeath);
      this.matchCauseOfDeath = matchCauseOfDeath;
      this.uploadFile.parseAsEna = parseAsEna;
      this.uploadFile.arrayData = data;
    },
    ...mapActions(['getSurveyMortalityPlausibility', 'cancelSurveyMoralityPlausibilityRequest']),
    async onPlausibilityRequest() {
      this.showPlausibilityModal = true;
      this.plausibilityRequestTime = 0;
      this.plausibilityRequestTimeIntervalId = setInterval(() => {
        this.plausibilityRequestTime += 1;
      }, 1000);
      const successful = await this.getSurveyMortalityPlausibility({
        projectId: this.projectId,
        surveyId: this.surveyId,
        lang: this.$i18n.locale
      });
      if (!successful) {
        let content = this.$t('components.notifications.errorRunningPlausibility');
        if (this.plausibilityError && this.plausibilityError.code === 409.11) {
          content = this.$t('components.notifications.notEnoughDataToGenerateReport');
        }
        this.$message.error({
          content,
          duration: 5
        });
        this.showPlausibilityModal = false;
      }
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
    },
    closePlausibilityModal() {
      this.showPlausibilityModal = false;
      this.plausibilityRequestTime = 0;
      if (this.plausibilityRequestTimeIntervalId) {
        clearInterval(this.plausibilityRequestTimeIntervalId);
        this.plausibilityRequestTimeIntervalId = null;
      }
      this.cancelSurveyMoralityPlausibilityRequest();
    },
    setDataFromStore() {
      const normData = cloneDeep(this.normalizedMortalityData);

      if (normData && normData.headers && normData.items) {
        const { items, headers } = normData;
        const hiddenColumnIndexes = [];
        headers.forEach((header, index) => {
          if (header.isHidden) {
            hiddenColumnIndexes.push(index);
          }
        });
        const translatedHeaders = this.translateHeaders();
        const headerNames = headers.map((item) => translatedHeaders.find((x) => x.key === item.key).title);
        const headerKeys = headers.map(item => item.key);
        const headerWidths = headers.map(item => item.width);
        const causeIndex = headerKeys.indexOf('cause');
        if (!this.showCauseOfDeath) {
          hiddenColumnIndexes.push(causeIndex);
        }
        const locationIndex = headerKeys.indexOf('location');
        if (!this.showLocationOfDeath) {
          hiddenColumnIndexes.push(locationIndex);
        }
        const headerConfigs = headers.map(item => {
          const result = (item?.config ? item.config : {});
          result.validator = null;
          if (item.config && 'editor' in item.config && item.config.editor === false) {
            result.editor = false;
          }
          if (this.isSupervisor) {
            result.readOnly = true;
            result.editor = false;
          }
          return result;
        });
        const dataValues = items.map(item => {
          const result = [];
          for (const headerKey of headers) {
            if (headerKey?.config?.dateFormat) {
              const dates = formatSmartDates(item[headerKey.key], headerKey.config.dateFormat);

              result.push(dates);
            } else if (headerKey?.config?.type === 'numeric') {
              result.push(convertStringToNumeric(item[headerKey.key]));
            } else {
              result.push(item[headerKey.key]);
            }
          }
          return result;
        });

        const clusterColIndex = headerKeys.indexOf('cluster');
        if (this.disableClusters) {
          headerConfigs[clusterColIndex].readOnly = true;
        }

        this.headerConfigs = headers;
        this.headerNames = headerNames;
        const duplicateHeader = headers.filter(item => item.key !== 'id');
        this.duplicateHeaderNames = duplicateHeader.map((item) => translatedHeaders.find((x) => x.key === item.key).title);
        this.duplicateHeaderkeys = duplicateHeader.map(item => item.key);
        this.headerKeys = headerKeys;
        this.data = dataValues;
        this.$refs.hot.hotInstance.updateSettings({
          colHeaders: headerNames,
          columns: headerConfigs,
          colWidths: headerWidths,
          data: dataValues,
          comments: false,
          hiddenColumns: {
            columns: hiddenColumnIndexes,
            indicators: false,
            copyPasteEnabled: false
          },
          cells: (row, column) => this.validateCellClassName(row, column, false)
        });
        this.reRenderingCells(false);
        this.updateCellErrorBarData();
      }
    },
    download() {
      const tableData = this.$refs.hot.hotInstance.getSourceData();
      if (tableData && this.headerKeys && this.headerNames) {
        const wb = XLSX.utils.book_new();
        const dataObjects = tableData.map((item) => {
          let result = {};
          this.headerConfigs.forEach((header, hIndex) => {
            if (header?.config?.dateFormat) {
              result = { ...result, [this.headerNames[hIndex]]: item[hIndex] ? moment(item[hIndex], header.config.dateFormat).toDate() : '' };
            } else if (header?.config?.type === 'numeric' || header?.key === 'id') {
              result = { ...result, [this.headerNames[hIndex]]: convertStringToNumeric(item[hIndex]) };
            } else {
              result = { ...result, [this.headerNames[hIndex]]: item[hIndex] };
            }
          });
          return result;
        });
        const ws = XLSX.utils.json_to_sheet(dataObjects, { header: this.headerNames, dense: true });
        this.headerConfigs.forEach((hConfig, index) => {
          if (hConfig?.config?.dateFormat) {
            for (let i = 0; i < dataObjects.length; i += 1) {
              const cellRef = XLSX.utils.encode_cell({ r: i + 1, c: index });
              ws[cellRef].z = 'yyyy-mm-dd';
            }
          }
        });
        XLSX.utils.book_append_sheet(wb, ws, 'data');

        const settingWs = getMortalitySettingsSheet(this.mortalitySettings);
        XLSX.utils.book_append_sheet(wb, settingWs, 'settings');

        XLSX.writeFile(wb, `survey ${this.surveyName} (${this.surveyId}) - mortality-data.xlsx`, { compression: true });
      }
    },
    async openOriginalSubmissionsDownloadModal() {
      this.showOriginalSubmissionsDownloadModal = true;
    },
    closeOriginalSubmissionsDownloadModal() {
      this.showOriginalSubmissionsDownloadModal = false;
    },
    async downloadAdditionalIndicators() {
      this.$message.loading({ content: this.$t('components.notifications.downloadingIndicators'), key: uploadMessageKey });
      const token = this.$store.getters.loggedIn
        ? this.$store.state.request.data.session.token
        : null;
      const axiosConfig = {
        method: 'GET',
        url: `/survey/${this.surveyId}/additional-indicators`,
        responseType: 'blob',
        params: {
          lang: this.$i18n.locale
        }
      };
      const { data } = await Vue.prototype.$http.request(
        configForPossibleBackendRequest(axiosConfig, token),
      );
      if (data) {
        downloadBase64FromBlob(
          data,
          'additionalIndicators.docx',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
        );
      }
    },
    resetStages() {
      this.stage = UploadStage.CHOOSE;
    },
    setCommentAtCell(row, column, commentAttr) {
      if (!this.commentCells[row]) {
        this.commentCells[row] = { [column]: commentAttr };
      } else {
        this.commentCells[row][column] = commentAttr;
      }
    },
    reRenderingCells(visual) {
      this.commentCells = {};
      const filterData = this.$refs.hot.hotInstance.getData();
      filterData.forEach((item, row) => {
        item.forEach((i, column) => {
          this.validateCellClassName(row, column, visual);
        });
      });
    },
    validateCellClassName(row, column, visual = false) {
      const cellClassNames = [];
      const instance = this.$refs.hot.hotInstance;
      const physicalRow = !this.filterApplied || visual ? instance.toPhysicalRow(row) : row;
      if (!physicalRow && physicalRow !== 0) {
        return;
      }
      const visualRow = !this.filterApplied || visual ? row : instance.toVisualRow(row);
      if (!visualRow && visualRow !== 0) {
        return;
      }
      const cellValue = instance.getDataAtCell(visualRow, column);
      let commentAttr = {};
      if (column === this.headerKeys.indexOf('survdate') && !this.hasValidDate(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.dateFormatNotAcceptable'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if ((column === this.headerKeys.indexOf('months') || column === this.headerKeys.indexOf('weight') || column === this.headerKeys.indexOf('height') || column === this.headerKeys.indexOf('muac') || column === this.headerKeys.indexOf('age')) && !this.hasValidNonNegativeNumber(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterNonNegativeValue'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('sex') && !this.hasValidSex(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterMaleOrFemale'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('hh') && (!cellValue && cellValue !== 0) && !instance.isEmptyRow(visualRow)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.hhColumnCannotContainMissingData'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('cluster')) {
        if (this.disableClusters) {
          cellClassNames.push('htDimmed');
        } else if (!instance.isEmptyCol(column) && (!cellValue && cellValue !== 0) && !instance.isEmptyRow(visualRow)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.clusterColumnMissingData'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if (column === this.headerKeys.indexOf('strata') && !instance.isEmptyCol(column) && (!cellValue && cellValue !== 0) && !instance.isEmptyRow(visualRow)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.strataColumnEntirelyEmptyOrFilled'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if ((column === this.headerKeys.indexOf('born') || column === this.headerKeys.indexOf('joined') || column === this.headerKeys.indexOf('left') || column === this.headerKeys.indexOf('died')) && !this.hasValidYesOneZero(cellValue)) {
        cellClassNames.push('htCommentCell red');
        commentAttr = {
          visualRow,
          color: 'red',
          errorMessage: this.$t('components.notifications.pleaseEnterYesOrNo'),
          errorTitle: this.$t('components.notifications.invalidEntry')
        };
        this.setCommentAtCell(physicalRow, column, commentAttr);
      } else if (column === this.headerKeys.indexOf('left')) {
        if (!this.hasValidLeftHouseholdCombination(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.householdMemberCombinationWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationDied(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.diedAndLeftWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if (column === this.headerKeys.indexOf('died')) {
        if (!this.hasValidDiedHouseholdCombination(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.householdMemberCombinationWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationLeft(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.diedAndLeftWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationBornJoined(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.diedAndBornJoinedWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if ((column === this.headerKeys.indexOf('joined'))) {
        if (!this.hasValidJoinedHouseholdCombination(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.householdMemberCombinationWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationBornDied(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.diedAndBornJoinedWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationBorn(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.joinedAndBornWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if ((column === this.headerKeys.indexOf('born'))) {
        if (!this.hasValidBornHouseholdCombination(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.householdMemberCombinationWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationDiedJoined(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.diedAndBornJoinedWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        } else if (!this.hasValidCombinationJoined(visualRow, cellValue)) {
          cellClassNames.push('htCommentCell orange');
          commentAttr = {
            visualRow,
            color: 'orange',
            errorMessage: this.$t('components.notifications.joinedAndBornWarning'),
            errorTitle: this.$t('components.notifications.warning')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      } else if ((column === this.headerKeys.indexOf('cause') || column === this.headerKeys.indexOf('location'))) {
        if (column === this.headerKeys.indexOf('cause')) {
          if (!this.hasCauseValueWhenDiedIsTrue(visualRow, cellValue)) {
            cellClassNames.push('htCommentCell red');
            commentAttr = {
              visualRow,
              color: 'red',
              errorMessage: this.$t('components.notifications.pleaseAddCauseForReportedDeath'),
              errorTitle: this.$t('components.notifications.invalidEntry')
            };
            this.setCommentAtCell(physicalRow, column, commentAttr);
          } else if (!this.hasValidCauseValue(cellValue)) {
            cellClassNames.push('htCommentCell red');
            commentAttr = {
              visualRow,
              color: 'red',
              errorMessage: this.$t('components.notifications.codeIncorrectPleaseRevise'),
              errorTitle: this.$t('components.notifications.invalidEntry')
            };
            this.setCommentAtCell(physicalRow, column, commentAttr);
          }
        } else if (!this.hasValidNonNegativeInteger(cellValue)) {
          cellClassNames.push('htCommentCell red');
          commentAttr = {
            visualRow,
            color: 'red',
            errorMessage: this.$t('components.notifications.pleaseEnterNonNegativeValue'),
            errorTitle: this.$t('components.notifications.invalidEntry')
          };
          this.setCommentAtCell(physicalRow, column, commentAttr);
        }
      }
      if ((!this.filterApplied || visual) && this.commentCells[physicalRow] && this.commentCells[physicalRow][column] && Object.keys(commentAttr).length === 0) {
        delete this.commentCells[physicalRow][column];
      }
      this.$refs.hot.hotInstance.setCellMeta(visualRow, column, 'className', cellClassNames.join(' '));
    },
    hasValidDate(value) {
      if (value) {
        return validDateFormat(value, dateFormats);
      }
      return true;
    },
    hasValidSex(value) {
      return (value === 'm' || value === 'f' || !value);
    },
    hasValidNonNegativeNumber(value) {
      return (!value && parseInt(value, 10) !== 0) || (!Number.isNaN(Number(value)) && Number(value) >= 0);
    },
    hasValidMeasure(value) {
      return (value === 'h' || value === 'l' || !value);
    },
    hasValidYesOneZero(value) {
      return (value === 'y' || value === '1' || value === '0' || !value);
    },
    hasValidCombinationLeft(row, value) {
      const leftColumn = this.headerKeys.indexOf('left');
      const left = this.$refs.hot.hotInstance.getDataAtCell(row, leftColumn);
      return !(value === 'y' && left === 'y');
    },
    hasValidCombinationDied(row, value) {
      const diedColumn = this.headerKeys.indexOf('died');
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      return !(value === 'y' && died === 'y');
    },
    hasValidCombinationBornJoined(row, value) {
      const bornColumn = this.headerKeys.indexOf('born');
      const joinedColumn = this.headerKeys.indexOf('joined');
      const born = this.$refs.hot.hotInstance.getDataAtCell(row, bornColumn);
      const joined = this.$refs.hot.hotInstance.getDataAtCell(row, joinedColumn);
      return !(value === 'y' && born === 'y' && joined === 'y');
    },
    hasValidCombinationBornDied(row, value) {
      const bornColumn = this.headerKeys.indexOf('born');
      const diedColumn = this.headerKeys.indexOf('died');
      const born = this.$refs.hot.hotInstance.getDataAtCell(row, bornColumn);
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      return !(value === 'y' && born === 'y' && died === 'y');
    },
    hasValidCombinationDiedJoined(row, value) {
      const diedColumn = this.headerKeys.indexOf('died');
      const joinedColumn = this.headerKeys.indexOf('joined');
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      const joined = this.$refs.hot.hotInstance.getDataAtCell(row, joinedColumn);
      return !(value === 'y' && died === 'y' && joined === 'y');
    },
    hasValidCombinationBorn(row, value) {
      const bornColumn = this.headerKeys.indexOf('born');
      const diedColumn = this.headerKeys.indexOf('died');
      const born = this.$refs.hot.hotInstance.getDataAtCell(row, bornColumn);
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      return !(value === 'y' && born === 'y' && died !== 'y');
    },
    hasValidCombinationJoined(row, value) {
      const joinedColumn = this.headerKeys.indexOf('joined');
      const diedColumn = this.headerKeys.indexOf('died');
      const joined = this.$refs.hot.hotInstance.getDataAtCell(row, joinedColumn);
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      return !(value === 'y' && joined === 'y' && died !== 'y');
    },
    hasCauseValueWhenDiedIsTrue(row, value) {
      const diedColumn = this.headerKeys.indexOf('died');
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      return died !== 'y' || (value || value === 0);
    },
    hasValidBornHouseholdCombination(row, value) {
      const diedColumn = this.headerKeys.indexOf('died');
      const joinedColumn = this.headerKeys.indexOf('joined');
      const leftColumn = this.headerKeys.indexOf('left');
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      const joined = this.$refs.hot.hotInstance.getDataAtCell(row, joinedColumn);
      const left = this.$refs.hot.hotInstance.getDataAtCell(row, leftColumn);
      return !(value === 'y' && joined === 'y' && left === 'y' && died === 'y');
    },
    hasValidDiedHouseholdCombination(row, value) {
      const bornColumn = this.headerKeys.indexOf('born');
      const joinedColumn = this.headerKeys.indexOf('joined');
      const leftColumn = this.headerKeys.indexOf('left');
      const born = this.$refs.hot.hotInstance.getDataAtCell(row, bornColumn);
      const joined = this.$refs.hot.hotInstance.getDataAtCell(row, joinedColumn);
      const left = this.$refs.hot.hotInstance.getDataAtCell(row, leftColumn);
      return !(value === 'y' && joined === 'y' && left === 'y' && born === 'y');
    },
    hasValidJoinedHouseholdCombination(row, value) {
      const bornColumn = this.headerKeys.indexOf('born');
      const diedColumn = this.headerKeys.indexOf('died');
      const leftColumn = this.headerKeys.indexOf('left');
      const born = this.$refs.hot.hotInstance.getDataAtCell(row, bornColumn);
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      const left = this.$refs.hot.hotInstance.getDataAtCell(row, leftColumn);
      return !(value === 'y' && born === 'y' && left === 'y' && died === 'y');
    },
    hasValidLeftHouseholdCombination(row, value) {
      const bornColumn = this.headerKeys.indexOf('born');
      const diedColumn = this.headerKeys.indexOf('died');
      const joinedColumn = this.headerKeys.indexOf('joined');
      const born = this.$refs.hot.hotInstance.getDataAtCell(row, bornColumn);
      const died = this.$refs.hot.hotInstance.getDataAtCell(row, diedColumn);
      const joined = this.$refs.hot.hotInstance.getDataAtCell(row, joinedColumn);
      return !(value === 'y' && joined === 'y' && born === 'y' && died === 'y');
    },
    hasValidCauseValue(value) {
      return (!value && value !== 0) || Object.keys(this.causeOfDeath).map(item => this.causeOfDeath[item].code).includes(parseInt(value, 10));
    },
    hasValidNonNegativeInteger(value) {
      return Number.isInteger(Number(value)) && Number(value) >= 0;
    },
    selectUploadfile(file) {
      this.stage = this.UploadStage.UPLOAD;
      this.uploadFile.file = file;
      this.uploadFile.name = file.name;
      const fileSize = file.size;
      const fileSizeKB = Math.round(fileSize * 0.01) / 10;
      const fileSizeMB = Math.round(fileSizeKB * 0.01) / 10;
      const fileSizeGB = Math.round(fileSizeMB * 0.01) / 10;
      if (fileSizeGB > 1)
        this.uploadFile.size = `${fileSizeGB}GB`;
      else if (fileSizeMB > 1)
        this.uploadFile.size = `${fileSizeMB}MB`;
      else
        this.uploadFile.size = `${fileSizeKB}KB`;
    },
    async uploadDataFromFile(data, fileName, parseAsEna = false) {
      const trimmedPathName = fileName.substring(0, fileName.lastIndexOf('.'));
      const shaped = data
        .filter(row => row.length > 0);

      const headerRow = shaped[0].map(headerName => headerName.toLowerCase());
      const coreHeaderConfigs = this.headerConfigs
        .filter((_, index) =>
          index !== this.headerKeys.indexOf('id'));

      try {
        for (let index = 1; index < shaped.length; index += 1) {
          const change = shaped[index];
          for (let headerIndex = 0; headerIndex < headerRow.length; headerIndex += 1) {
            if (change[headerIndex] != null) {
              switch (headerRow[headerIndex]) {
                case 'sex':
                  if (change[headerIndex] === 'M' || change[headerIndex] === 'F') {
                    change[headerIndex] = change[headerIndex].toLowerCase();
                  }
                  break;
                case 'survdate':
                  change[headerIndex] = formatSmartDates(change[headerIndex]);
                  break;
                case 'born':
                case 'joined':
                case 'left':
                case 'died':
                  if (parseInt(change[headerIndex], 10) === 0 || change[headerIndex]?.toLowerCase?.()?.trim?.() === 'n') {
                    change[headerIndex] = null;
                  } else if (parseInt(change[headerIndex], 10) === 1 || change[headerIndex]?.trim?.() === 'Y') {
                    change[headerIndex] = 'y';
                  }
                  break;
                default:
              }
            }
          }
        }
        const chunkedDataUpload = chunkDataUploadArray(shaped.slice(1), parseAsEna);
        const projectId = this.$store.state.survey.project.id;
        const { surveyId } = this.$store.state.survey;
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;

        const axiosConfig = {
          method: 'POST',
          url: `/projects/${projectId}/mortality/${surveyId}`,
          data: []
        };
        const updatePromises = chunkedDataUpload.map(dataItemsToUpload => {
          const reqData = parseAsEna
            ? shapeEnaFileUpload(headerRow, dataItemsToUpload, trimmedPathName)
            : shapeUploadRequestData(coreHeaderConfigs, headerRow, dataItemsToUpload, trimmedPathName);
          axiosConfig.data = reqData;

          return Vue.prototype.$http.request(
            configForPossibleBackendRequest(axiosConfig, token)
          );
        });
        const responses = await Promise.all(updatePromises);
        const itemsToUpdate = responses
          .reduce((prevUpdates, nextResponse) => prevUpdates.concat(nextResponse.data), []);
        this.$refs.hot.hotInstance.batch(() => {
          this.updateTableAfterFileSave(itemsToUpdate);
        });

        this.$message.success({
          content: this.$t('components.notifications.importedDataFileReset'),
          key: uploadMessageKey,
          duration: 5
        });
        this.isUploading = false;
        this.countRows();
        this.onEntryChange();
        this.reRenderingCells(true);
        this.updateCellErrorBarData();
      } catch (error) {
        // eslint-disable-next-line no-console
        this.$message.error({ content: this.$t('components.notifications.errorUploadingData'), duration: 5 });
      } finally {
        this.visible = false;
        this.stage = UploadStage.CHOOSE;
      }
    },
    async saveRow(changes) {
      if (this.backspaceDown && !(this.disableClusters && changes.length === 1 && changes[0][1] === this.headerKeys.indexOf('cluster'))) {
        this.$message.loading({ content: this.$t('components.notifications.deletingData'), key: uploadMessageKey });
        this.backspaceDown = false;
      }
      const filtered = filterEmptyChanges(changes);
      if (filtered.length === 0) return;
      try {
        const projectId = this.$store.state.survey.project.id;
        const { surveyId } = this.$store.state.survey;
        const token = this.$store.getters.loggedIn
          ? this.$store.state.request.data.session.token
          : null;

        const axiosConfig = {
          method: 'POST',
          url: `/projects/${projectId}/mortality/${surveyId}`,
          data: []
        };

        const chunkedDataUpload = chunkDataUploadArray(filtered);
        const updatePromises = chunkedDataUpload.map(dataItemsToUpload => {
          const shapedChanges = shapeMortalityUpdateRequestData(
            dataItemsToUpload,
            this.$refs.hot.hotInstance,
            this.headerConfigs,
            this.headerKeys
          );
          axiosConfig.data = shapedChanges;

          return Vue.prototype.$http.request(
            configForPossibleBackendRequest(axiosConfig, token)
          );
        });

        const responses = await Promise.all(updatePromises);
        const itemsToUpdate = responses
          .reduce((prevUpdates, nextResponse) => prevUpdates.concat(nextResponse.data), []);

        this.reRenderingCells(true);
        this.$refs.hot.hotInstance.batchRender(() => {
          this.updateTableAfterSave(itemsToUpdate);
        });
        this.$message.success({ content: this.$t('components.notifications.dataUpdated'), key: uploadMessageKey });
        this.updateCellErrorBarData();
        this.countRows();
        this.onEntryChange();
      } catch (error) {
        const changesToReverse = [];
        for (const [row, column, old] of changes) {
          changesToReverse.push([row, column, old]);
        }
        this.$refs.hot.hotInstance.setDataAtCell(changesToReverse, 'reversing_change');
        this.$message.error({ content: this.$t('components.notifications.errorSavingRow'), duration: 5 });
      } finally {
        if (this.waitingForDataSetDeleteRequest === true) {
          this.closeDeleteDataSetModal();
          this.waitingForDataSetDeleteRequest = false;
        }
      }
    },
    getRowIndexById(id) {
      const idColumnIndex = this.headerKeys.indexOf('id');

      return this.data
        .findIndex((row) =>
          String(row[idColumnIndex]) === String(id));
    },
    updateTableAfterSave(response) {
      const rowsToRemove = response
        .filter((item) =>
          item[0] === 'deleted' &&
          item[1] != null &&
          item[1]?.id != null &&
          !item[1].changes?.some((chng) => chng.includes('clear_row')))
        .map((item) => [
          // map row numbers to be deleted
          item[1]?.changes[0] ? item[1].changes[0][0] : -1,
          1
        ])
        .filter((item) => item[0] !== -1);

      const changesToReverse = response
        .filter((item) => item[0] === 'revert' && item[1] && item[1].changes)
        .map((item) => item[1].changes)
        .map((changes) => changes
          .map(([row, column, old]) => [row, column, old]));


      const creations = response
        .filter(
          (item) => (item[0] === 'created' || item[0] === 'updated') && item[1] && item[1].changes &&
          item[1].changes &&
          item[1].id != null
        )
        .map(item => item[1]);

      const idUpdates = creations.map(creation => [
        [creation.changes[0][0], this.headerKeys.indexOf('id'), creation.id]
      ]);

      this.$refs.hot.hotInstance.setDataAtCell(flatten(idUpdates), 'update_id');
      this.$refs.hot.hotInstance.setDataAtCell(flatten(changesToReverse, true), 'reversing_change');

      if (rowsToRemove.length > 0) {
        this.$refs.hot.hotInstance.alter('remove_row', rowsToRemove, 'remove_row_by_api_response');
        this.$refs.hot.hotInstance.deselectCell();
        this.reRenderingCells(true);
      }
    },
    updateCellErrorBarData() {
      const columnSet = new Set();
      const rowSet = new Set();
      this.cellErrorCount = 0;
      Object.keys(this.commentCells).forEach(row => {
        Object.keys(this.commentCells[row]).forEach(column => {
          if (this.commentCells[row][column].color === 'red') {
            this.cellErrorCount = this.cellErrorCount + 1;
            columnSet.add(this.headerKeys[column].toUpperCase());
            rowSet.add(parseInt(this.commentCells[row][column].visualRow, 10) + 1);
          }
        });
      });
      this.cellErrorColumns = Array.from(columnSet).join(', ');
      const reduceRows = {};
      Array.from(rowSet).forEach(item => {
        if (item - 1 in reduceRows) {
          reduceRows[item] = reduceRows[item - 1];
          delete reduceRows[item - 1];
        } else {
          reduceRows[item] = item;
        }
      });
      this.reduceSequence(reduceRows);
      this.cellErrorRows = '';
      Object.keys(reduceRows).forEach(item => {
        let cellErrorRows = '';
        if (parseInt(reduceRows[item], 10) !== parseInt(item, 10)) {
          cellErrorRows = `${reduceRows[item]}-${item}`;
        } else {
          cellErrorRows = `${item}`;
        }
        this.cellErrorRows = this.cellErrorRows !== '' ? `${this.cellErrorRows}, ${cellErrorRows}` : cellErrorRows;
      });
    },
    reduceSequence(dict) {
      for (const item of Object.keys(dict)) {
        if (dict[item] - 1 in dict) {
          /* eslint-disable no-param-reassign */
          const minItem = dict[dict[item] - 1];
          delete dict[dict[item] - 1];
          dict[item] = minItem;
          return this.reduceSequence(dict);
          /* eslint-disable no-param-reassign */
        }
      }
      return dict;
    },
    updateTableAfterFileSave(response) {
      const itemsToAdd = response.map(item => omit(item[1], 'changes'));
      const dataValues = itemsToAdd.map(item => {
        const result = [];
        for (const headerKey of this.headerConfigs) {
          if (headerKey?.config?.dateFormat) {
            const dates = formatSmartDates(item[headerKey.key], headerKey.config.dateFormat);

            result.push(dates);
          } else {
            result.push(item[headerKey.key]);
          }
        }
        return result;
      });

      const rowToEdit =
        this.$refs.hot.hotInstance.countSourceRows() -
        this.$refs.hot.hotInstance.countEmptyRows(true);

      const changes = [];
      dataValues.forEach((row, rowIndex) => {
        row.forEach((val, colIndex) => {
          changes.push([rowIndex + rowToEdit, colIndex, val]);
        });
      });
      this.$refs.hot.hotInstance.setDataAtCell(changes, 'file_upload');
    },
    countRows() {
      const rowSummaryObject = {};
      const hhIndex = this.headerKeys.indexOf('hh');
      const clusterIndex = this.headerKeys.indexOf('cluster');
      const teamIndex = this.headerKeys.indexOf('team');
      const sexIndex = this.headerKeys.indexOf('sex');
      const joinedIndex = this.headerKeys.indexOf('joined');
      const leftIndex = this.headerKeys.indexOf('left');
      const bornIndex = this.headerKeys.indexOf('born');
      const diedIndex = this.headerKeys.indexOf('died');
      const colData = this.$refs.hot.hotInstance.getData();

      colData.forEach(row => {
        const hh = row[hhIndex];
        const cluster = row[clusterIndex];
        const team = row[teamIndex];
        const sex = row[sexIndex];

        const joined = row[joinedIndex];
        const left = row[leftIndex];
        const born = row[bornIndex];
        const died = row[diedIndex];

        const isMale = sex === 'm' || String(sex) === '1';
        const isFemale = sex === 'f' || String(sex) === '0';
        const hasJoined = joined === 'y' || String(joined) === '1';
        const hasLeft = left === 'y' || String(left) === '1';
        const isBorn = born === 'y' || String(born) === '1';
        const hasDied = died === 'y' || String(died) === '1';

        if (!hh) return;

        if (hh in rowSummaryObject && cluster in rowSummaryObject[hh]) {
          rowSummaryObject[hh][cluster].members += 1;
          if (isMale) {
            rowSummaryObject[hh][cluster].male += 1;
          }
          if (isFemale) {
            rowSummaryObject[hh][cluster].female += 1;
          }

          if (hasJoined) {
            rowSummaryObject[hh][cluster].joined += 1;
          }
          if (hasLeft) {
            rowSummaryObject[hh][cluster].left += 1;
          }
          if (isBorn) {
            rowSummaryObject[hh][cluster].born += 1;
          }
          if (hasDied) {
            rowSummaryObject[hh][cluster].died += 1;
          }
        } else if (!(hh in rowSummaryObject)) {
          rowSummaryObject[hh] = {
            [cluster]: {
              members: 1,
              cluster,
              team,
              male: isMale ? 1 : 0,
              female: isFemale ? 1 : 0,
              joined: hasJoined ? 1 : 0,
              left: hasLeft ? 1 : 0,
              born: isBorn ? 1 : 0,
              died: hasDied ? 1 : 0
            }
          };
        } else {
          rowSummaryObject[hh][cluster] = {
            members: 1,
            cluster,
            team,
            male: isMale ? 1 : 0,
            female: isFemale ? 1 : 0,
            joined: hasJoined ? 1 : 0,
            left: hasLeft ? 1 : 0,
            born: isBorn ? 1 : 0,
            died: hasDied ? 1 : 0
          };
        }
      });
      this.rowSummaryObject = rowSummaryObject;
      this.householdsCount = Object.values(rowSummaryObject).reduce(
        (previousValue, currentValue) => previousValue + Object.keys(currentValue).length, 0
      );
    },
    translateHeaders() {
      const translatedHeaders = [
        {
          key: 'survdate',
          title: this.$t('components.titles.survdate')
        }, {
          key: 'cluster',
          title: this.$tc('components.titles.cluster', 1)
        }, {
          key: 'team',
          title: this.$tc('components.titles.team', 1)
        }, {
          key: 'id',
          title: this.$t('values.id')
        }, {
          key: 'hh',
          title: this.$t('components.titles.hh')
        }, {
          key: 'sex',
          title: this.$t('components.titles.sex')
        }, {
          key: 'age',
          title: this.$t('values.age')
        }, {
          key: 'joined',
          title: this.$t('components.titles.joined')
        }, {
          key: 'left',
          title: this.$t('components.titles.left')
        }, {
          key: 'cause',
          title: this.$t('components.titles.cause')
        }, {
          key: 'died',
          title: this.$t('components.titles.died')
        }, {
          key: 'source',
          title: this.$t('components.titles.source')
        }, {
          key: 'location',
          title: this.$t('components.titles.location')
        }, {
          key: 'source',
          title: this.$t('components.titles.source')
        }, {
          key: 'born',
          title: this.$t('components.titles.born')
        }
      ];
      return translatedHeaders;
    },
    translateCauseOfDeath(causeOfDeath) {
      switch (causeOfDeath) {
        case 'Traumatic':
          return this.$t('components.labels.traumatic');
        case 'Non-Traumatic':
          return this.$t('components.labels.nonTraumatic');
        case 'Unknown':
          return this.$t('components.labels.unknown');

        default:
          return causeOfDeath;
      }
    },
    deleteDataSet(dataset) {
      this.deleteDataSetModalVisible = true;
      this.dataSetToDelete = dataset;
    },
    closeDeleteDataSetModal() {
      this.deleteDataSetModalVisible = false;
      this.dataSetToDelete = '';
    },
    setDataFromDeleteDatasetModal(changes) {
      this.waitingForDataSetDeleteRequest = true;
      this.$refs.hot.hotInstance.setDataAtCell(changes);
    }
  }
};
</script>

<style lang="scss">
.loading-container {
  text-align: center;
  padding-top: 30px;
  min-height: 300px;
}
.expand-error {
  background-color: rgba(244, 40, 40, 0.1);
  margin-bottom: 20px;
  padding-right: 20px;
}
.rotate180 {
    -webkit-transform: rotate(180deg);
    -moz-transform: rotate(180deg);
    -o-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }
.TabButton {
  width: 195px;
  height: 48px;
  padding: 9px 4px 9px 13px;
  border-radius: 1px;
  margin-left: auto;
}
.TabButton-disabled {
  width: 195px;
  height: 48px;
  padding: 9px 4px 9px 13px;
  border-radius: 1px;
  margin-left: auto;
  opacity: 0.5;
  pointer-events: none;
}
.disabled-color {
  color: #cccccc;
}
.Tab {
  font-size: 17px;
  font-weight: bold;
}
.Container {
  height: 300px;
}
.Rectangle {
  height: 76px;
  margin: 20px 0 20px 1px;
  padding: 25px;
  font-size: 16px;
  background-color: #f4f4f5;
  display: flex;
}
.UPLOAD-ENUMERATORS-F {
  width: 519px;
  height: 39px;
  margin: 20px 0 0;
  padding: 0 50px 9px 38px;
  opacity: 0.23;
  font-size: 24px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: -0.2px;
  text-align: center;
  color: #363534;
}
.upload {
  width: 100%;
  margin: 20px 1px 25px 2px;
  padding: 47px 52px 47px 73px;
  border-radius: 3px;
  border: dashed 2px #bebebe;
  text-align: center;
  justify-content: flex-end;
}
.code-picker {
  width: 100%;
}
.code-map-container {
  height: 80%;
  overflow: auto;
}
.result-data-entry {
  .handsontable thead tr td:first-child {
    display: none;
  }

  .red-color {
    color: #f42828;
  }
  .htCommentTextArea.red {
    border: 1px solid #f42828;
    border-left: 3px solid #f42828;
  }

  .handsontable .htCommentCell.red {
    background: rgba(244, 40, 40, 0.08);
    color: #f42828;
  }
  .handsontable .htCommentCell.red:after {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #f42828;
  }

  .handsontable .htDimmed {
    background: #f4f4f5;
  }

  .table-footer {
    border-top: 1px solid #ccc;
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 23px;
  }

  .handsontable span.colHeader.columnSorting::before {
    content: none;
  }
  .orange-color {
    color: #f49402;
  }
  .htCommentTextArea.orange {
    border: 1px solid #f49402;
    border-left: 3px solid #f49402;
  }
  .handsontable .htCommentCell.orange {
    background: rgba(250, 255, 0, 0.25);
    color: #f49402;
  }
  .handsontable .htCommentCell.orange:after {
    position: absolute;
    top: 0;
    right: 0;
    border-left: 6px solid transparent;
    border-top: 6px solid #f49402;
  }
}
</style>
